import '@otmandelieu/scss/app.scss';
import '@otmandelieu/scss/app-editor.scss';
import '@otmandelieu/scss/app-salon.scss';
import '@otmandelieu/scss/app-congres.scss';
import '@otmandelieu/scss/app-bateau.scss';
import '@otmandelieu/scss/print.scss';
import '@otmandelieu/js/bootstrap.bundle';
import Ux from '@otmandelieu/js/services/ux';
import Form from '@otmandelieu/js/services/form';
import Map from '@otmandelieu/js/services/map';
import Weather from '@otmandelieu/js/services/weather';
import Slider from '@otmandelieu/js/services/slider';
import Gallery from '@otmandelieu/js/services/gallery';
import Socials from '@otmandelieu/js/services/socials';
import Navbar from '@otmandelieu/js/services/navbar';

document.addEventListener( "DOMContentLoaded", function () {
    new Ux();
    new Form();
    new Map();
    new Weather();
    new Slider();
    new Gallery();
    new Socials();
    new Navbar();
} );
